import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { useState } from 'react';
import Button, { KINDS } from '../Button';
import { ConfirmModal } from '../Modal/ConfirmModal';
const propTypes = {
    onClick: PropTypes.func.isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    modalProps: PropTypes.object,
    active: PropTypes.bool,
    className: PropTypes.string,
    triggerKind: PropTypes.oneOf(KINDS),
    isTriggerSmall: PropTypes.bool,
    confirmKind: PropTypes.oneOf(KINDS),
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    eventTracking: PropTypes.object,
};
export default function ButtonWithConfirm(props) {
    const { onClick = () => { }, onOpen = () => { }, onClose = () => { }, triggerKind, isTriggerSmall, className, modalProps, children, confirmLabel, confirmKind, cancelLabel, disabled, tooltip, eventTracking, active = false, } = props;
    const [modalOpen, setModalOpen] = useState(false);
    const onOpenConfirmModal = () => {
        onOpen();
        if (active) {
            setModalOpen(true);
        }
        else {
            onClick();
        }
    };
    const onCloseConfirmModal = () => {
        onClose();
        setModalOpen(false);
    };
    const onConfirm = () => {
        onCloseConfirmModal();
        onClick();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: onOpenConfirmModal, kind: triggerKind, isSmall: isTriggerSmall, className: className, disabled: disabled, "data-tooltip": tooltip, "aria-label": props['aria-label'], eventTracking: eventTracking, children: children }), _jsx(ConfirmModal, Object.assign({}, modalProps, { confirmLabel: confirmLabel, onClose: onCloseConfirmModal, onCancel: onCloseConfirmModal, onConfirm: onConfirm, confirmKind: confirmKind, cancelLabel: cancelLabel, isOpen: modalOpen }))] }));
}
ButtonWithConfirm.propTypes = propTypes;
