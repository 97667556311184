import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@emotion/css';
import { IconX } from 'featherico';
import vars from '../../../vars';
import Button from '../../components/Button';
import { Card, CardHeader, CardHeaderButtons, CardHeaderHeading, CardSection, CardSectionBody, } from '../../components/Cards';
import { useAppContext } from '../../hooks/useAppContext';
import CreateSampleContent from './CreateSampleContent';
import RemoveSampleContent from './RemoveSampleContent';
const cardWrapper = css({ marginTop: `calc(${vars.standardpadding} * 2)` });
export default function WelcomePromotion({ onDismiss }) {
    const { user: { company }, } = useAppContext();
    return (_jsxs(Card, { className: cardWrapper, children: [_jsxs(CardHeader, { children: [_jsx(CardHeaderHeading, { children: "Welcome to Small Improvements!" }), _jsx(CardHeaderButtons, { children: _jsx(Button, { onClick: onDismiss, kind: "PLAIN", children: _jsx(IconX, {}) }) })] }), _jsx(CardSection, { children: _jsxs(CardSectionBody, { children: [company.hasSampleUsers && _jsx(RemoveSampleContent, {}), !company.hasSampleUsers && _jsx(CreateSampleContent, {})] }) })] }));
}
