import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@emotion/css';
import { useState } from 'react';
import DeprecatedButtonContainer from '../../components/ButtonContainer';
import ButtonWithConfirm from '../../components/ButtonWithConfirm';
import triggerPopupAlert, { SHORT_DURATION } from '../../components/PopupAlert/trigger';
import SaveButton from '../../components/SaveButton';
import { trackSprigEvent } from '../../services/Sprig';
import { trackEvent } from '../../services/Tracking';
import { deleteSampleContent } from '../Admin/SampleContentPage/services/actions';
const buttonContainer = css({ justifyContent: 'center' });
export default function RemoveSampleContent() {
    const [resetting, setResetting] = useState(false);
    const handleResetSampleContent = () => {
        setResetting(true);
        trackEvent({ category: 'DASHBOARD', action: 'click', label: 'Remove Sample Content' });
        trackSprigEvent('Sample Content Removed');
        deleteSampleContent().catch(e => {
            var _a, _b, _c;
            setResetting(false);
            triggerPopupAlert((_c = (_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : `Deletion failed. Please try again or contact support.`, 'error', {
                timeoutMs: SHORT_DURATION,
            });
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx("p", { children: "This is your company dashboard. In the top-left corner you can switch between the \"Employee\" and \"Admin\" perspectives. To help you get started, we have created some example users and content for you. If you would like to remove these and start from a blank slate, click \"Remove Sample Content\" below." }), _jsxs(DeprecatedButtonContainer, { className: buttonContainer, children: [resetting && _jsx(SaveButton, { state: "SAVING", disabled: true }), !resetting && (_jsx(ButtonWithConfirm, { onClick: handleResetSampleContent, triggerKind: "PRIMARY", active: true, confirmKind: "WARNING", confirmLabel: "Remove Sample Content", modalProps: {
                            title: 'Remove Sample Content?',
                            body: (_jsx(_Fragment, { children: "Removing all sample content will leave you with an empty Small Improvements account." })),
                        }, eventTracking: { category: 'DASHBOARD', action: 'click', label: 'Remove Sample Content' }, children: "Remove Sample Content" }))] })] }));
}
