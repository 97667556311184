import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@emotion/css';
import { useState } from 'react';
import DeprecatedButtonContainer from '../../components/ButtonContainer';
import triggerPopupAlert, { SHORT_DURATION } from '../../components/PopupAlert/trigger';
import SaveButton, { ENABLED } from '../../components/SaveButton';
import { trackSprigEvent } from '../../services/Sprig';
import { trackEvent } from '../../services/Tracking';
import { resetSampleContent } from '../Admin/SampleContentPage/services/actions';
const buttonContainer = css({ justifyContent: 'center' });
export default function CreateSampleContent() {
    const [resetting, setResetting] = useState(false);
    const handleResetSampleContent = () => {
        setResetting(true);
        trackEvent({ category: 'DASHBOARD', action: 'click', label: 'Create Sample Content' });
        trackSprigEvent('Sample Content Created');
        resetSampleContent({ canResetSampleContent: true, needDeletionPassword: false, deletionPassword: '' }).catch(e => {
            var _a, _b, _c;
            setResetting(false);
            triggerPopupAlert((_c = (_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : `Creation failed. Please try again or contact support.`, 'error', {
                timeoutMs: SHORT_DURATION,
            });
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx("p", { children: "This is your company dashboard. In the top-left corner you can switch between the \"Employee\" and \"Admin\" perspectives. If you would like to test drive the application using some example users, click \"Create Sample Content\" below." }), _jsx(DeprecatedButtonContainer, { className: buttonContainer, children: _jsx(SaveButton, { onClick: handleResetSampleContent, kind: "PRIMARY", labels: { [ENABLED]: 'Create Sample Content', SAVING: 'Creating...' }, state: resetting ? 'SAVING' : 'ENABLED' }) })] }));
}
